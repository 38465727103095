export interface Path {
  id: string;
  name: string;
  positions: CameraPosition[];
  delay: number;
}

export interface CameraPosition {
  position: Vector;
  rotation: Rotation;
  // up?: Vector;
  // forward?: Vector;
}

export interface Vector {
  x: number;
  y: number;
  z: number;
}

export interface Rotation {
  pitch: number;
  yaw: number;
}
