import { Injectable } from '@angular/core';
import { Path } from './path.models';
import { BehaviorSubject } from 'rxjs';

const STORAGE_KEY = 'seurat_testbed_paths';

@Injectable({
  providedIn: 'root',
})
export class PathService {
  private paths$ = new BehaviorSubject<Path[]>([]);

  constructor() {
    this.paths$.next(this.load());
  }

  getAll$() {
    return this.paths$.asObservable();
  }

  get(id: string) {
    console.log(this.load());
    return this.load().find((m) => m.id === id);
  }

  create(): Path {
    return {
      id: crypto.randomUUID(),
      name: '',
      positions: [],
      delay: 500,
    };
  }

  addOrUpdate(path: Path) {
    const items = this.load();
    const idx = items.findIndex((m) => m.id === path.id);
    if (idx > -1) {
      items[idx] = path;
    } else {
      items.push(path);
    }
    this.save(items);
    this.paths$.next(items);
  }

  remove(id: string) {
    const items = this.load().filter((m) => m.id !== id);
    this.save(items);
    this.paths$.next(items);
  }

  removeAll() {
    this.save([]);
    this.paths$.next([]);
  }

  deserialize(data: string) {
    const items = JSON.parse(data as string) as Path[];
    this.save(items);
    this.paths$.next(items);
  }

  serialize() {
    return JSON.stringify(this.load());
  }

  private load() {
    const data = localStorage.getItem(STORAGE_KEY);
    if (!data) return [];
    let result = JSON.parse(data) as Path[];
    result = result.sort((a, b) => a.name.localeCompare(b.name));
    return result;
  }

  private save(items: Path[]) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(items));
  }
}
