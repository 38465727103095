import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { LOGIN_ROUTE } from '../routes';

export const authGuard: CanActivateFn = () => {
  const authService = inject(OAuthService);

  if (authService.hasValidIdToken()) {
    return true;
  } else {
    inject(Router).navigate([LOGIN_ROUTE]);
  }

  return false;
};
