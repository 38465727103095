import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ViewService } from '../view/view.service';
import { Path } from './path.models';
import { PathService } from './path.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    RouterModule,
  ],
  template: `
    <div class="flex flex-col h-full">
      <nav class="flex items-center p-2 border-b justify-end">
        <div class="flex-grow">
          <button
            mat-icon-button
            matTooltip="Upload paths from a JSON file"
            (click)="inputFile.click()"
          >
            <mat-icon>upload</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Download paths to a JSON file"
            (click)="export()"
          >
            <mat-icon>download</mat-icon>
          </button>
        </div>
        <button
          mat-icon-button
          matTooltip="Delete all paths"
          (click)="removeAll()"
          [disabled]="(paths$ | async)?.length === 0"
        >
          <mat-icon>delete_forever</mat-icon>
        </button>
        <button
          mat-icon-button
          color="accent"
          matTooltip="Add new path"
          routerLink="new"
        >
          <mat-icon>add</mat-icon>
        </button>
      </nav>

      <div class="overflow-y-auto h-full">
        <mat-nav-list>
          @for (item of paths$|async; track item) {
          <mat-list-item [routerLink]="item.id">
            <mat-icon matListItemIcon>route</mat-icon>
            <div matListItemTitle>{{ item.name }}</div>
            <div matListItemMeta>
              <button
                mat-icon-button
                matTooltip="Play path"
                (click)="play(item); $event.stopPropagation()"
              >
                <mat-icon>play_arrow</mat-icon>
              </button>
              <button
                mat-icon-button
                matTooltip="Remove path"
                (click)="remove(item); $event.stopPropagation()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </mat-list-item>
          }
        </mat-nav-list>
      </div>
    </div>

    <input hidden type="file" name="inputfile" #inputFile id="inputfile" />
  `,
})
export class PathListComponent implements AfterViewInit {
  paths$ = this.pathService.getAll$();

  constructor(
    private pathService: PathService,
    private viewService: ViewService
  ) {}

  ngAfterViewInit(): void {
    this.bindInputFile();
  }

  export() {
    const data = this.pathService.serialize();
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = 'paths.json';
    a.click();
    window.URL.revokeObjectURL(a.href);
  }

  play(path: Path) {
    this.viewService.play(path);
  }

  remove(path: Path) {
    if (
      confirm(
        'Are you sure you want to delete this path? This cannot be reversed.'
      )
    ) {
      this.pathService.remove(path.id);
    }
  }

  removeAll() {
    if (
      confirm(
        'Are you sure you want to delete ALL the paths? This cannot be reversed.'
      )
    ) {
      this.pathService.removeAll();
    }
  }

  private bindInputFile() {
    const service = this.pathService;

    (<any>document)
      .getElementById('inputfile')
      .addEventListener('change', (event: any) => {
        const reader = new FileReader();
        reader.onload = function () {
          if (reader.result) {
            if (
              confirm(
                'Are you sure you want to continue. This will overwrite all your paths.'
              )
            ) {
              service.deserialize(reader.result as string);
            }
          } else {
            console.error('bindInputFile: reader.result is not defined.');
          }
        };
        reader.readAsText(event.target.files[0]);
      });
  }
}
