import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { TILESETS_ROUTE } from '../routes';
import { Tileset } from './tileset.models';
import { TilesetService } from './tileset.service';

interface Delay {
  title: string;
  delay: number;
}

const delays = [
  { title: 'Delay 0.5s', delay: 500 },
  { title: 'Delay 1.0s', delay: 1000 },
  { title: 'Delay 2.0s', delay: 2000 },
  { title: 'Delay 3.0s', delay: 3000 },
  { title: 'Delay 5.0s', delay: 5000 },
  { title: 'Delay 10.0s', delay: 10000 },
] as Delay[];

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
  ],
  template: `
    <div class="flex flex-col h-full">
      <nav class="flex items-center p-2 border-b">
        <button
          mat-icon-button
          matTooltip="Return to tilesets"
          (click)="back()"
        >
          <mat-icon>arrow_back_ios</mat-icon>
        </button>
        <span class="flex-grow flex items-center justify-end">
          <button
            mat-icon-button
            color="accent"
            matTooltip="Save tileset"
            [disabled]="formGroup.pristine || formGroup.invalid"
            (click)="save()"
          >
            <mat-icon>save</mat-icon>
          </button>
        </span>
      </nav>

      <div class="mt-3 p-3 flex flex-col">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput [formControl]="formGroup.controls.name" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>URL</mat-label>
          <input matInput [formControl]="formGroup.controls.url" />
        </mat-form-field>
      </div>
    </div>
  `,
})
export class TilesetFormComponent implements OnInit {
  formGroup = this.createFormGroup();
  delays = delays;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private tilesetService: TilesetService
  ) {}

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.params['id'];
    const path = this.tilesetService.get(id) ?? this.tilesetService.create();
    this.formGroup.patchValue(path);
  }

  save() {
    const path = this.formGroup.value as Tileset;
    this.tilesetService.addOrUpdate(path);
    this.formGroup.markAsPristine();
  }

  back() {
    this.router.navigate([TILESETS_ROUTE]);
  }

  private createFormGroup() {
    return new FormGroup({
      id: new FormControl<string>(''),
      name: new FormControl<string>('', {
        validators: [Validators.required],
      }),
      url: new FormControl<string>('', {
        validators: [Validators.required],
      }),
    });
  }
}
