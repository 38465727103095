import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule, MatSelectionList } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { TILESETS_ROUTE } from '../routes';
import { ScanService } from '../scan/scan.service';
import { Tileset } from './tileset.models';
import { TilesetService } from './tileset.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
  ],
  template: `
    <div class="flex flex-col h-full">
      <nav class="flex items-center p-2 border-b">
        <button mat-icon-button matTooltip="Return to paths" (click)="back()">
          <mat-icon>arrow_back_ios</mat-icon>
        </button>
        <span class="flex-grow flex items-center justify-end">
          <button
            mat-icon-button
            matTooltip="Import selected tilesets"
            (click)="import()"
          >
            <mat-icon>playlist_add</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Select all" (click)="selectAll()">
            <mat-icon>select_all</mat-icon>
          </button>
        </span>
      </nav>

      <div class="overflow-y-auto h-full">
        <mat-selection-list #selectionList>
          @for(item of tilesets$ | async ; track item.id){
          <mat-list-option [value]="item">{{ item.name }}</mat-list-option>
          } @empty {
          <div class="italic p-4">Fetching tilesets from Connect...</div>
          }
        </mat-selection-list>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    `,
  ],
})
export class TilesetImportComponent {
  private allSelected = false;
  @ViewChild('selectionList') selectionList!: MatSelectionList;
  tilesets$ = this.scanService.tilesets$;

  constructor(
    private router: Router,
    private scanService: ScanService,
    private tilesetService: TilesetService
  ) {}

  import() {
    const selected = this.selectionList.selectedOptions.selected.map(
      (option) => option.value as Tileset
    );
    this.tilesetService.import(selected);
  }

  back() {
    this.router.navigate([TILESETS_ROUTE]);
  }

  selectAll() {
    if (this.allSelected) this.selectionList.deselectAll();
    else this.selectionList.selectAll();
    this.allSelected = !this.allSelected;
  }
}
