import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ViewService } from '../view/view.service';

@UntilDestroy()
@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule,
  ],
  template: `
    <div class="flex flex-col h-full" [formGroup]="formGroup">
      <nav class="flex items-center p-2 border-b">
        <span class="flex-grow flex items-center justify-end">
          <button
            mat-icon-button
            color="accent"
            matTooltip="Save path"
            [disabled]="formGroup.pristine || formGroup.invalid"
            (click)="save()"
          >
            <mat-icon>save</mat-icon>
          </button>
        </span>
      </nav>

      <div class="mx-3 mt-3 flex space-x-2">
        <mat-form-field appearance="outline">
          <mat-label>Point Budget</mat-label>
          <input
            type="number"
            matInput
            class="text-right"
            [formControl]="formGroup.controls.pointBudget"
          />
        </mat-form-field>
      </div>
      <div class="mx-3 mt-3 flex space-x-2">
        <mat-form-field appearance="outline">
          <mat-label>Point Density</mat-label>
          <input
            type="number"
            matInput
            class="text-right"
            [formControl]="formGroup.controls.pointDensity"
          />
        </mat-form-field>
      </div>
    </div>
  `,
})
export class SettingsFormComponent {
  formGroup = this.createFormGroup();

  constructor(private viewService: ViewService) {
    viewService.settings$.pipe(untilDestroyed(this)).subscribe((settings) => {
      if (!settings) return;
      this.formGroup.patchValue(settings);
    });
  }

  save() {
    const settings = {
      pointBudget: this.formGroup.controls.pointBudget.value ?? -1,
      pointDensity: this.formGroup.controls.pointDensity.value ?? -1,
    };
    this.viewService.setSettings(settings);
    this.formGroup.markAsPristine();
  }

  private createFormGroup() {
    return new FormGroup({
      pointBudget: new FormControl<number>(0),
      pointDensity: new FormControl<number>(0),
    });
  }
}
