/* eslint-disable @typescript-eslint/no-empty-function */
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragPlaceholder,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { CameraPosition } from './path.models';
import { ViewService } from '../view/view.service';

@Component({
  selector: 'tb-position-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CdkDropList,
    CdkDrag,
    CdkDragPlaceholder,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PositionListComponent,
      multi: true,
    },
  ],
  template: `
    <mat-list cdkDropList (cdkDropListDropped)="drop($event)">
      @for (item of segments; track item; let index = $index) {
      <mat-list-item cdkDrag class="cursor-grab">
        <div
          mat-list-item
          class="cdk-drag-placeholder"
          *cdkDragPlaceholder
        ></div>
        <mat-icon matListItemIcon>location_on</mat-icon>
        <div matListItemTitle>
          {{ item.position.x | number : '1.1-1' }},
          {{ item.position.y | number : '1.1-1' }},
          {{ item.position.z | number : '1.1-1' }}
        </div>
        <div matListItemMeta>
          <button mat-icon-button (click)="viewClick(item)">
            <mat-icon>location_searching</mat-icon>
          </button>
          <button mat-icon-button (click)="removeClick(item)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-list-item>
      }
    </mat-list>
  `,
  styles: [
    `
      .cdk-drag-preview {
        box-sizing: border-box;
        border-radius: 4px;
        height: 48px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
          0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
      }

      .cdk-drag-placeholder {
        box-sizing: border-box;
        border-radius: 4px;
        height: 48px;
        opacity: 0;
      }

      .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }
    `,
  ],
})
export class PositionListComponent implements ControlValueAccessor {
  private firstWrite = true;
  private onChange: (value: CameraPosition[]) => void = () => {};
  private onTouched: () => void = () => {};

  @Input() segments: CameraPosition[] = [];

  constructor(
    private viewService: ViewService,
    private cdRef: ChangeDetectorRef
  ) {}

  writeValue(obj: CameraPosition[]): void {
    this.segments = obj;
    this.cdRef.markForCheck();
  }

  registerOnChange(fn: (value: CameraPosition[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState() {}

  viewClick(item: CameraPosition) {
    this.viewService.flyTo(item);
  }
  removeClick(item: CameraPosition) {
    const idx = this.segments.indexOf(item);
    this.segments.splice(idx, 1);
    this.onChange(this.segments);
    this.onTouched();
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log('drop', event, this.segments);
    moveItemInArray(this.segments, event.previousIndex, event.currentIndex);
    this.onChange(this.segments);
    this.onTouched();
  }
}
