import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { PATHS_ROUTE } from '../routes';
import { ViewService } from '../view/view.service';
import { CameraPosition, Path } from './path.models';
import { PathService } from './path.service';
import { PositionListComponent } from './position-list.component';

interface Delay {
  title: string;
  delay: number;
}

const delays = [
  { title: 'Delay 0.5s', delay: 500 },
  { title: 'Delay 1.0s', delay: 1000 },
  { title: 'Delay 2.0s', delay: 2000 },
  { title: 'Delay 3.0s', delay: 3000 },
  { title: 'Delay 5.0s', delay: 5000 },
  { title: 'Delay 10.0s', delay: 10000 },
] as Delay[];

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
    PositionListComponent,
  ],
  template: `
    <div class="flex flex-col h-full" [formGroup]="formGroup">
      <nav class="flex items-center p-2 border-b">
        <button mat-icon-button matTooltip="Return to paths" (click)="back()">
          <mat-icon>arrow_back_ios</mat-icon>
        </button>
        <span class="flex-grow flex items-center justify-end">
          <button mat-icon-button matTooltip="Play the path" (click)="play()">
            <mat-icon>play_arrow</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Add current camera position to path"
            (click)="add()"
          >
            <mat-icon>add_location</mat-icon>
          </button>
          <button
            mat-icon-button
            color="accent"
            matTooltip="Save path"
            [disabled]="formGroup.pristine || formGroup.invalid"
            (click)="save()"
          >
            <mat-icon>save</mat-icon>
          </button>
        </span>
      </nav>

      <div class="mx-3 mt-3 flex space-x-2">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput [formControl]="formGroup.controls.name" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Delay</mat-label>
          <mat-select [formControl]="formGroup.controls.delay">
            @for(item of delays; track item){
            <mat-option [value]="item.delay">{{ item.title }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="overflow-y-auto h-full">
        <tb-position-list
          [formControl]="formGroup.controls.positions"
        ></tb-position-list>
      </div>
    </div>
  `,
})
export class PathFormComponent implements OnInit {
  formGroup = this.createFormGroup();
  delays = delays;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pathService: PathService,
    private viewService: ViewService
  ) {}

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.params['id'];
    const path = this.pathService.get(id) ?? this.pathService.create();
    this.formGroup.patchValue(path);
  }

  save() {
    const path = this.formGroup.value as Path;
    this.pathService.addOrUpdate(path);
    this.formGroup.markAsPristine();
  }

  add() {
    this.formGroup.controls.positions.setValue([
      ...(this.formGroup.controls.positions.value ?? []),
      this.viewService.positionProvider(),
    ]);

    this.formGroup.controls.positions.markAsDirty();
  }

  play() {
    const path = this.formGroup.value as Path;
    this.viewService.play(path);
  }

  back() {
    this.router.navigate([PATHS_ROUTE]);
  }

  private createFormGroup() {
    return new FormGroup({
      id: new FormControl<string>(''),
      name: new FormControl<string>('', {
        validators: [Validators.required],
      }),
      positions: new FormControl<CameraPosition[]>([]),
      delay: new FormControl<number>(500),
    });
  }
}
