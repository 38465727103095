import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, map, switchMap } from 'rxjs';
import { Scan, TilesetDto } from './scan.models';
import { Tileset } from '../tileset/tileset.models';

// const URL =
//   'https://qa-api.scandata.trimblegeospatial.com/san/projects/sQitdcordgA/pointclouds';

const URL =
  'https://api.scandata.trimblegeospatial.com/neu/projects/JhAq7x_2USo/pointclouds';

@Injectable({
  providedIn: 'root',
})
export class ScanService {
  constructor(private http: HttpClient) {}

  tilesets$: Observable<Tileset[]> = this.getTilesets$();

  private getTilesets$() {
    return this.http.get<Scan[]>(URL).pipe(
      switchMap((scans) => {
        const urls = scans.map((scan) =>
          this.http
            .get<TilesetDto[]>(`${URL}/${scan.id}/tilesets`)
            .pipe(map((tilesets) => ({ scan, tilesets })))
        );

        return forkJoin(urls);
      }),
      map((result) => {
        return result
          .flatMap((item) =>
            item.tilesets.map((tileset) => ({
              ...tileset,
              name: item.scan.name,
            }))
          )
          .filter((item) => item.format === 'potree' && item.status === 'Ready')
          .map((item) => {
            return { id: item.id, name: item.name, url: item.rootUrl };
          });
      }),
      map((result) => result.sort((a, b) => a.name.localeCompare(b.name)))
    );
  }
}
