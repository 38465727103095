import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { TILESETS_IMPORT_ROUTE } from '../routes';

@Component({
  template: ` <div class="p-4">Logging in. Please wait.</div> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  constructor(oauthService: OAuthService, router: Router) {
    oauthService
      .loadDiscoveryDocumentAndLogin()
      .then(() => router.navigate([TILESETS_IMPORT_ROUTE]));
  }
}
