import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Tileset } from '../tileset/tileset.models';
import { CameraPosition, Path } from '../path/path.models';
import { ViewSettings } from './view-settings';

const STORAGE_KEY = 'seurat_testbed_viewsettings';

@Injectable({
  providedIn: 'root',
})
export class ViewService {
  private _tileset$ = new BehaviorSubject<Tileset | null>(null);
  private _flyTo$ = new Subject<CameraPosition>();
  private _play$ = new Subject<Path>();
  private _settings$ = new BehaviorSubject<ViewSettings | null>(null);

  public readonly tileset$ = this._tileset$.asObservable();
  public readonly flyTo$ = this._flyTo$.asObservable();
  public readonly play$ = this._play$.asObservable();
  public readonly settings$ = this._settings$.asObservable();

  public positionProvider = this.defaultPositionProvider;

  setTileset(tileset: Tileset | null) {
    this._tileset$.next(tileset);
  }

  setDefaultSettings(defaultSettings: Partial<ViewSettings>) {
    const settings = this.getSettings(defaultSettings);
    this._settings$.next(settings);
  }

  setSettings(settings: ViewSettings) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(settings));
    this._settings$.next(settings);
  }

  flyTo(position: CameraPosition) {
    this._flyTo$.next(position);
  }

  play(path: Path) {
    this._play$.next(path);
  }

  private getSettings(defaultSettings: Partial<ViewSettings>): ViewSettings {
    const json = localStorage.getItem(STORAGE_KEY);
    if (json) return JSON.parse(json);

    return {
      ...{
        pointBudget: 1 * 1000 * 1000,
        pointDensity: 1,
      },
      ...defaultSettings,
    };
  }

  private defaultPositionProvider(): CameraPosition {
    return {
      position: { x: 0, y: 0, z: 0 },
      rotation: { pitch: 0, yaw: 0 },
    };
  }
}
