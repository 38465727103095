import { AuthConfig, OAuthModuleConfig } from 'angular-oauth2-oidc';

export const authCodeFlowConfig: AuthConfig = {
  issuer: 'https://id.trimble.com',
  redirectUri: window.location.origin + '/login',
  clientId: 'd9931ef6-83a0-4a9f-8702-806105ee05f1',
  responseType: 'code',
  scope: 'openid SeuratAPI',
  showDebugInformation: true,
};

export const authModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: ['https://api.scandata.trimblegeospatial.com'],
    sendAccessToken: true,
  },
};
