import { Injectable } from '@angular/core';
import { Tileset } from './tileset.models';
import { BehaviorSubject } from 'rxjs';

const LIST_STORAGE_KEY = 'seurat_testbed_tilesets';
const SELECTED_ID_STORAGE_KEY = 'seurat_testbed_tileset_selected';

@Injectable({
  providedIn: 'root',
})
export class TilesetService {
  private _tilesets$ = new BehaviorSubject<Tileset[]>([]);
  private _selected$ = new BehaviorSubject<Tileset | null>(null);

  tilesets$ = this._tilesets$.asObservable();
  selected$ = this._selected$.asObservable();

  constructor() {
    const items = this.loadList();
    const selectedId = this.loadSelectedId();

    this._tilesets$.next(items);

    const selected = items.find((item) => item.id === selectedId);
    if (selected) {
      this._selected$.next(selected);
    }
  }

  select(tileset: Tileset) {
    if (tileset.id === this.loadSelectedId()) {
      this.saveSelectedId(null);
      this._selected$.next(null);
    } else {
      this.saveSelectedId(tileset.id);
      this._selected$.next(tileset);
    }
  }

  get(id: string) {
    console.log(this.loadList());
    return this.loadList().find((m) => m.id === id);
  }

  create(): Tileset {
    return {
      id: crypto.randomUUID(),
      name: '',
      url: '',
    };
  }

  addOrUpdate(tileset: Tileset) {
    const items = this.loadList();
    const idx = items.findIndex((m) => m.id === tileset.id);
    if (idx > -1) {
      items[idx] = tileset;
    } else {
      items.push(tileset);
    }
    this.saveList(items);
    this._tilesets$.next(items);
  }

  import(newItems: Tileset[]) {
    let items = this.loadList();
    const urls = items.map((item) => item.url);
    const itemsToAdd = newItems.filter((item) => urls.indexOf(item.url) === -1);

    items = [...items, ...itemsToAdd];
    this.saveList(items);
    this._tilesets$.next(items);
  }

  remove(id: string) {
    const items = this.loadList().filter((m) => m.id !== id);
    this.saveList(items);
    this._tilesets$.next(items);
  }

  removeAll() {
    this.saveList([]);
    this._tilesets$.next([]);
  }

  deserialize(data: string) {
    const items = JSON.parse(data as string) as Tileset[];
    this.saveList(items);
    this._tilesets$.next(items);
  }

  serialize() {
    return JSON.stringify(this.loadList());
  }

  private loadList() {
    const data = localStorage.getItem(LIST_STORAGE_KEY);
    if (!data) return [];
    let result = JSON.parse(data) as Tileset[];
    result = result.sort((a, b) => a.name.localeCompare(b.name));
    return result;
  }

  private saveList(items: Tileset[]) {
    localStorage.setItem(LIST_STORAGE_KEY, JSON.stringify(items));
  }

  private loadSelectedId() {
    return localStorage.getItem(SELECTED_ID_STORAGE_KEY);
  }

  private saveSelectedId(id: string | null) {
    if (id === null) localStorage.removeItem(SELECTED_ID_STORAGE_KEY);
    else localStorage.setItem(SELECTED_ID_STORAGE_KEY, id);
  }
}
