import { Route } from '@angular/router';
import { TilesetListComponent } from './tileset/tileset-list.component';
import { PathFormComponent } from './path/path-form.component';
import { PathListComponent } from './path/path-list.component';
import { TilesetFormComponent } from './tileset/tileset-form.component';
import { TilesetImportComponent } from './tileset/tileset-import.component';
import { SettingsFormComponent } from './settings/settings-form.component';
import { authGuard } from './auth/auth-guard';
import { LoginComponent } from './auth/login.component';

export const LOGIN_ROUTE = 'login';
export const PATHS_ROUTE = 'paths';
export const PATH_ROUTE = 'paths/:id';
export const TILESETS_ROUTE = 'tilesets';
export const TILESETS_IMPORT_ROUTE = 'tilesets/import';
export const TILESET_ROUTE = 'tilesets/:id';
export const SETTING_ROUTE = 'settings';

export const testbedRoutes: Route[] = [
  { path: LOGIN_ROUTE, component: LoginComponent },
  { path: PATHS_ROUTE, component: PathListComponent },
  { path: PATH_ROUTE, component: PathFormComponent },
  { path: TILESETS_ROUTE, component: TilesetListComponent },
  {
    path: TILESETS_IMPORT_ROUTE,
    component: TilesetImportComponent,
    canActivate: [authGuard],
  },
  { path: TILESET_ROUTE, component: TilesetFormComponent },
  { path: SETTING_ROUTE, component: SettingsFormComponent },
  { path: '**', redirectTo: TILESETS_ROUTE },
];
