import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PATHS_ROUTE, SETTING_ROUTE, TILESETS_ROUTE } from './routes';
import { TilesetService } from './tileset/tileset.service';
import { ViewService } from './view/view.service';

@UntilDestroy()
@Component({
  selector: 'tb-side-panel',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatIconModule,
    MatTabsModule,
    MatToolbarModule,
    RouterModule,
  ],
  template: `
    <div class="flex flex-col h-full">
      <nav mat-toolbar class="flex bg-gray-500 text-white justify-center p-1">
        <span>{{ title }}</span>
      </nav>
      <div>
        <nav mat-tab-nav-bar [tabPanel]="tabPanel" class="bg-gray-100 border-b">
          <a
            mat-tab-link
            [routerLink]="tilesetsRoute"
            routerLinkActive
            #rla2="routerLinkActive"
            [active]="rla2.isActive"
          >
            <mat-icon>grain</mat-icon>
            <span class="ml-1">Tilesets</span>
          </a>
          <a
            mat-tab-link
            [routerLink]="pathsRoute"
            routerLinkActive
            #rla1="routerLinkActive"
            [active]="rla1.isActive"
          >
            <mat-icon>route</mat-icon>
            <span class="ml-1">Paths</span>
          </a>
          <a
            mat-tab-link
            [routerLink]="settingsRoute"
            routerLinkActive
            #rla1="routerLinkActive"
            [active]="rla1.isActive"
          >
            <mat-icon>settings</mat-icon>
            <span class="ml-1"></span>
          </a>
        </nav>
      </div>
      <mat-tab-nav-panel class="flex-grow overflow-y-auto" #tabPanel
        ><router-outlet></router-outlet
      ></mat-tab-nav-panel>
      <div
        class=" bg-gray-300 text-white text-xs p-1 space-x-3 flex justify-center"
      >
        <span class="bg-red-800 p-1 px-2 rounded-lg"
          >Point Budget: {{ (settings$ | async)?.pointBudget | number }}</span
        >
        <span class="bg-red-800 p-1 px-2 rounded-lg"
          >Point Density: {{ (settings$ | async)?.pointDensity | number }}</span
        >
      </div>
    </div>
  `,
})
export class SidePanelComponent {
  pathsRoute = PATHS_ROUTE;
  tilesetsRoute = TILESETS_ROUTE;
  settingsRoute = SETTING_ROUTE;

  settings$ = this.viewService.settings$;

  @Input() title = '';

  constructor(
    private tilesetService: TilesetService,
    private viewService: ViewService
  ) {
    this.tilesetService.selected$
      .pipe(untilDestroyed(this))
      .subscribe((item) => this.viewService.setTileset(item));
  }
}
